.search-bar-component {
  margin-top: 1rem;
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  .search-bar {
    display: flex;
    width: 60%;
    position: relative;
    height: 50px;
    align-items: center;
    background-color: #fff;
    border-radius: 14px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    @media (max-width: 480px) {
      width: 100%;
      height: 36px;
    }
    input[type='text'] {
      border: none;
      padding-left: 12px;
      flex-grow: 1;
      border-radius: 4px;
      outline: none;
    }
    .search-icon {
      color: #0073e6;
      background: none;
      border: none;
      cursor: pointer;
      padding: 10px;
      font-size: 18px;
      @media (max-width: 480px) {
        font-size: 15px;
      }
    }
    .product-list {
      box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
        rgba(17, 17, 26, 0.1) 0px 0px 8px;
      position: absolute;
      // z-index: 99999;
      width: 100%;
      position: absolute;
      top: 100%;
      width: 100%;
      max-height: 300px;
      overflow-y: scroll;
      border: 1px solid #ddd;
      border-radius: 7px;
      margin-top: 9px;
      background: white;
      .each-product {
        margin: 0.5rem;
        padding: 0.6rem;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #dcdcdc;
        .product-img {
          margin-right: 1rem;
          height: 70px;
          width: 70px;
          border: 1px solid #ddd;
          border-radius: 50%;
          object-fit: contain;
        }
        .mrp {
          color: #64676b;
          font-size: 14px;
        }
        .product-name {
          color: #14539a;
          font-weight: 500;
        }
      }
      .each-product:hover {
        cursor: pointer;
        background-color: #f3f3f2;
        border-radius: 7px;
      }
    }
  }
}

.store-list-custom-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  .store-profile-pic {
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 50px;
    border: 1px solid #ddd;
  }
  .store-profile-cnt {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    text-align: center;
  }
  th {
    text-align: center;
    background-color: #f2f2f2;
  }
  .action-wrapper {
    justify-content: center;
    position: relative;
    display: flex;
    align-items: center;
    .three-dots {
      cursor: pointer;
      font-size: 18px;
    }
    .action-menu {
      position: absolute;
      top: 100%;
      right: 0;
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 4px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      display: none;
      flex-direction: column;
      z-index: 1;
      button {
        padding: 8px;
        border: none;
        background-color: transparent;
        cursor: pointer;
        text-align: left;
        width: 100%;
        &:hover {
          background-color: #f2f2f2;
        }
      }
    }
  }
  .open {
    .action-menu {
      display: flex;
    }
  }
}
