.formGrid {
  display: flex;
  flex-wrap: wrap;
  max-height: 80vh; // Set maximum height as needed
  overflow-y: auto; // Enable vertical scrolling if content overflows
  // padding: 20px;
}

.formField {
  margin: 10px;
}

.formButton {
  margin-left: 80%;
  padding: 10px 20px;
  background-color: rgb(128, 223, 107) !important;
  color: black;
  border: blue;
  cursor: pointer;
  border-radius: 4px;
}

.formButton:hover {
  color: black;
  background-color: #0056b3 !important;
}

// .formInput {
//   display: flex;
//   flex-direction: column;
//   margin-top: 10px;
// }

.error {
  color: red;
  font-size: 12px;
}

