.formSelectInput select {
  width: 100%;
  padding: 8px;
  border: 1px solid gray;
  border-radius: 4px;
  transition: border-color 0.2s;
}
label {
  font-size: 12px;
  color: gray;
}

.formSelectInput select.open {
  border-color: #007bff;
}

.formSelectInput select:focus {
  outline: none;
}