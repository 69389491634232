@tailwind base;
@tailwind components;
@tailwind utilities;

/* F0ECE3
DFD3C3
C7B198
A68DAD */

:root {
  --main-btn-and-progress-color: #5b4af6;
  --disabled-color-of-button: #e5e7eb;
  --second-priority-color: #14539a;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  overflow-y: scroll;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

svg {
  display: inline-block;
}

input,
select {
  outline: none;
}

input[type="file"]::-webkit-file-upload-button {
  cursor: pointer;
}

/* CSS */

/* .btn-disabled {
    cursor: not-allowed;
} */

.button-primary-disabled {
  background-color: var(--disabled-color-of-button);
  border-radius: 7px;
  box-sizing: border-box;
  cursor: not-allowed;
  color: black;
  height: 45.6px;
  width: 10.7rem;
  font-weight: 400;
  font-size: 15px;
  outline: none;
  outline: 0;
  text-align: center;
}

.button-primary {
  background-color: #14539a;
  border-radius: 7px;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  height: 45.6px;
  width: 10.7rem;
  font-weight: 400;
  font-size: 15px;
  outline: none;
  outline: 0;
  text-align: center;
  transform: translateY(0);
  transition:
    transform 150ms,
    box-shadow 150ms;
}

.button-primary:hover {
  box-shadow:
    rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  transform: translateY(-2px);
}

@media (min-width: 768px) {
  .button-primary {
    padding: 10px 30px;
  }
}

.dropdown {
  width: 30rem;
  position: relative;
}

.dropdown:hover .dropdown-list {
  opacity: 1;
  visibility: visible;
}

.dropdown-select {
  padding: 1.5rem;
  border-radius: 4px;
  background-color: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.6rem;
  cursor: pointer;
}

.dropdown-list {
  border-radius: 4px;
  background-color: white;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.2s linear,
    visibility 0.2s linear;
  transform: translateY(10px);
}

.dropdown-list:before {
  content: "";
  width: 100%;
  height: 10px;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
}

.dropdown-list__item {
  padding: 1rem;
  font-size: 1.4rem;
}

/* header of multistep form */

.progress {
  background-color: #14539a;
  position: absolute;
  top: 12%;
  left: 0;
  height: 0.3rem;
  width: 0;
  transform: translateY(-50%);
  z-index: -1;
  transition: 0.4s ease;
}

.circle {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease;
}

.circle.active {
  border-color: #14539a;
}

.btn {
  background-color: #3498db;
  color: #fff;
  border: 0;
  cursor: pointer;
  border-radius: 0.8rem;
  padding: 0.6rem 1rem;
  font-family: inherit;
  margin: 0.5rem;
  font-size: 1rem;
}

.btn:active {
  transform: scale(0.8);
}

.btn:focus {
  outline: 0;
}

.btn:disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
}

.progress-bar::-webkit-progress-bar {
  background-color: #e5e7eb;
}

.progress-bar::-webkit-progress-value {
  background-color: #14539a !important;
}

.progress-bar::-moz-progress-bar {
  background: #14539a;
}

.progress-bar::-webkit-progress-value {
  background: #14539a;
}

.MuiFormLabel-root,
.MuiInputLabel-root,
.MuiInputLabel-formControl,
.MuiInputLabel-animated,
.MuiInputLabel-outlined,
.MuiFormLabel-colorPrimary,
.MuiInputLabel-root,
.MuiInputLabel-formControl,
.MuiInputLabel-animated,
.MuiInputLabel-outlined,
.inputLabel,
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: "#E5E7EB" !important;
}

.pac-container:after {
  background-image: none !important;
  height: 0px;
}

.gmnoprint {
  margin: 0 !important;
}
