:root {
  --main-color: #14539a;
  --text-primary: #0c3558;
  --gray: #efefef;
}

.input {
  border: 1px solid #e5e7eb;
  padding: 0.25rem 0.75rem;
  border-radius: 0.375rem;
}

.parent_cnt {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    height: auto !important;
  }
  input[type='text'] {
    font-size: 15px !important;
  }
}

.cnt {
  max-width: 440px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  .user_icon_cnt {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--main-color);
    .user_icon {
      width: 120px;
      background-color: var(--gray);
      height: 120px;
      font-size: 50px;
      padding: 18px;
      border-radius: 50%;
      font-weight: 550;
    }
  }
  .heading {
    font-size: 2rem;
    font-weight: 600;
    color: var(--text-primary);
  }
  .greeting {
    color: var(--text-primary);
    font-size: 0.85rem;
    line-height: 1.3rem;
    @media (max-width: 480px) {
      line-height: 1.3rem;
      padding: 0 0.9rem;
    }
  }
}

.send_otp_btn {
  background-color: var(--main-color);
  padding: 0.55rem 0.75rem;
  font-size: 0.95rem;
  cursor: pointer;
  border-radius: 30px !important;
  color: white;
  outline: none;
  border: none;
  width: 100%;
  @media (max-width: 576px) {
    width: 89%;
    font-size: small;
  }
}

.send_otp_btn:hover {
  background-color: #175dac;
}

.otp {
  .cnt_otp {
    margin: 1rem 0 0.5rem 0;
    .otp_field {
      border: 1px solid #e5e7eb;
      border-radius: 0.36rem;
      padding: 0rem;
      width: 45px;
      height: 45px;
      text-align: center;
      margin: 0 0.7rem;
      display: inline-block;
      @media (max-width: 576px) {
        width: 30px;
        height: 30px;
      }
      @media (max-width: 315px) {
        width: 23.2px;
        height: 25px;
        font-size: 12px;
      }
      @media (max-width: 290px) {
        width: 20px;
        height: 25px;
      }
    }
  }
  .resend_otp {
    text-align: right;
    color: #0c3558;
    margin-bottom: 0.5rem;
    font-size: 0.89rem;
    font-weight: 460;
    @media (max-width: 576px) {
      margin-right: 1.5rem;
    }
    a {
      text-decoration: none;
    }
  }
}

.active_border {
  border: 1px solid var(--main-color);
}

.phone_number {
  .css-1efuh7f-MuiFormControl-root-MuiTextField-root .MuiInputBase-input {
    text-align: center;
  }
  @media (max-width: 353px) {
    margin: 0 0.6rem;
    .css-1efuh7f-MuiFormControl-root-MuiTextField-root .MuiInputBase-input {
      padding: 0.75rem 0.7rem !important;
      text-align: center;
    }
  }
  .phone_number_field {
    border: 1px solid #e5e7eb;
    border-radius: 0.36rem;
    width: 240px;
    margin: 0 0.3rem;
    display: inline-block;
  }
  @media (max-width: 576px) {
    .css-jf4g8p-MuiFormControl-root-MuiTextField-root {
      width: 59%;
    }
  }
}

.error {
  color: red;
  font-size: 0.85rem;
  display: block;
  margin-bottom: 0.9rem;
}

.country_code {
  text-align: center;
  margin-right: 0.5rem;
  border: 1px solid #e5e7eb;
  width: 75px;
}

.shopkeeper_details {
  font-size: 2rem;
  font-weight: 400;
  margin-bottom: 0.7rem;
  color: var(--text-primary);
  text-align: center;
}

// image
.image-container {
  position: relative;
}

.image-wrapper {
  position: relative;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  border: 1px dashed #ccc;
  border-radius: 5px;
  overflow: hidden;
}

.image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.placeholder {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.9);
  opacity: 1;
  cursor: pointer;
}

.upload {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #333;
  text-align: center;
  width: 100%;
  height: 100%;
  border: 1px dashed #ccc;
  border-radius: 5px;
}

.upload:hover {
  background-color: #f9f9f9;
}

.remove {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  font-size: 10px;
}

.remove:hover {
  background-color: #f9f9f9;
}

.remove span {
  font-size: 14px;
  color: #333;
}
