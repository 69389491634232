.arrayField,
.objectField {
  width: 100%;
  display: block;
  border: 1px solid #ddd; /* Add border for differentiation */
  padding: 10px; /* Add padding for visual separation */
  margin-bottom: 20px; /* Space below the element */
  margin-top: 20px;
  clear: both;
}

.formArrayInput {
  display: flex;
  flex-direction: column;
}

.formArrayInputItem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.formArrayInputItem input {
  flex: 1 1 40%; /* Ensure inputs take 40% of the available space and can shrink */
  margin-right: 10px; /* Add space between input and buttons */
}

.formArrayInputItemList {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}

.formArrayButton {
  margin-left: 80%;
  padding: 10px 20px;
  background-color: rgb(128, 223, 107) !important;
  color: black;
  border: blue;
  cursor: pointer;
  border-radius: 4px;
}

formArrayButton:hover {
  color: black;
  background-color: #0056b3 !important;
}