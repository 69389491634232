:root {
    --main-color: #14539a;
    --bordercolor: #e5e7eb;
    --product-bg-color: #eef2f6;
}



.AddIcon{
    position: fixed;
    bottom: 2.5rem;
    right: 3.5rem;
    // padding-left: ;
    // text-align: center;
    font-weight: 900;
    font-size: 19.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    // -webkit-text-stroke: 20px black;
    // flex: ;
    // margin-bottom: 1rem;
    // margin-right: 2rem;
    color: var(--product-bg-color);
    width: 40px;
    height: 40px;
    background: var(--main-color); 
    // -moz-border-radius: 70px; 
    // -webkit-border-radius: 70px; 
    border-radius: 70px;
}